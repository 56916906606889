.gameLaunchWrapper {
  background-color: var(--background-color);
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  *, & {
    box-sizing: border-box;
  }

  .gameLaunchBox {
    display: flex;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 16px 16px 12px 16px;

    .gameLaunchHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 11px 17px;
      background: var(--card-background-color);
      color: #FFFFFF;
      border-radius: 16px 16px 0 0;
      gap: 8px;
      border: 1px solid var(--border-color);
      border-bottom: unset;

      .headerTop {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .gameLaunchHeaderLeftSide {
          .gameLaunchHeaderTitle {
            font-weight: bold;
            color: var(--text-color);
          }
        }

        .switchContainer {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .gameLaunchHeaderRightSide {
          display: flex;
          align-items: center;

          .newTabContainer {
            height: 20px;
            width: 20px;
            cursor: pointer;
          }

          .gameLaunchHeaderRightItem {
            height: 20px;
            width: 20px;
            cursor: pointer;
            margin-left: 12px;

            button {
              span {
                svg {
                  fill: var(--placeholder-text-color);

                  &:hover {
                    fill: var(--text-color);
                  }
                }
              }
            }
          }
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: var(--divider-color);
      }

      .headerBottom {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 16px;

        .playerInfo {
          span {
            color: var(--text-color);
            line-height: 20px;
          }

          .scoreText {
            color: var(--amount-color);
          }
        }

        .unJoinedText {
          display: flex;
          align-items: center;
          gap: 4px;

          span {
            color: #EAC33B;
          }

        }
      }
    }

    .gameLaunchBody {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 0 0 16px 16px;
      background-color: var(--card-background-color);
      padding: 0 16px 16px;
      border: 1px solid var(--border-color);
      border-top: unset;

      iframe {
        width: 100%;
        border: none;
        border-radius: 16px;
      }
    }
  }
}

@media screen and (max-width: 1280px ) {
  .gameLaunchWrapper {
    .gameLaunchBox {
      border-radius: unset;
      padding: unset;

      .gameLaunchHeader {
        border-radius: unset;
      }

      .gameLaunchBody {
        border-radius: unset;
        padding: 0;

        iframe {
          border-radius: unset;
        }
      }
    }
  }
}