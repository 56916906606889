.noticeContainer {
  padding: 12px;
  border: 1px solid #E35656;
  border-radius: 8px;
  background-color: var(--card-background-color);
  margin-top: 20px;

  .noticeContent {
    display: flex;
    gap: 8px;

    .noticeIcon {
      width: 20px;
      height: 20px;

    }

    .noticeText {
      span {
        color: var(--text-color);
        word-break: break-all;
      }
    }
  }
}