.tournamentItemStartDateWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  .tournamentItemStartDateTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
  }

  .tournamentItemStartDate {
    display: flex;

    .tournamentItemStartDateItem {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-right: 12px;
      margin-right: 12px;
      flex: 0 0 37px;

      &::after {
        content: "";
        position: absolute;
        top: 9px;
        right: 0;
        width: 1px;
        height: 24px;
        background-color: var(--divider-color);
      }

      &:last-child::after {
        content: none;
      }

      .tournamentItemStartDateTimeNumber {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-color);
      }

      .tournamentItemStartDateTimeDay {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: var(--text-color);
      }
    }

    .tournamentItemStartDateItemMobile {
      margin-right: 0;
    }
  }
}
