.buttonsContainer {

  .content {
    display: flex;
    align-items: center;
    width: 100%;

    .icon {
      display: flex;
      align-items: center;
      padding: 12px;
      height: 24px;
      position: relative;
      border-right: 1px solid var(--border-color);

      button {
        color: var(--text-color)
      }
    }
  }
}