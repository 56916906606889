.tournamentListWrapper {
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;

  .tournamentListSubWrapper {
    padding: 48px 0 32px 0;
    max-width: 1400px;
    width: 100%;

    .skeleton {
      li {
        height: 218px;
        width: 100% !important;
        border-radius: var(--item-card-border-radius);
      }
    }

    .tournamentListTabs {
      display: flex;
      margin-top: 32px;
      overflow: auto;
      gap: 16px;

      &::-webkit-scrollbar {
        height: 5px;
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      button {
        cursor: pointer;
        padding: 12px 24px;
        border: 0;
        background-color: var(--card-background-color);
        border-radius: var(--button-border-radius);
        color: var(--text-color);
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;

        &.active {
          background-color: var(--primary-color);
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 0.9;
        }
      }

      .tabActive {
        background-color: var(--primary-color);
        color: var(--button-text-color);
      }
    }

    .tournamentListTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: var(--text-color);
    }

    .tournamentList {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
    }

    .tournamentGrid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 32px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .tournamentListWrapper {
    .tournamentListSubWrapper {
      padding: 40px 0 32px 0;
      max-width: 1200px;

      .tournamentListTabs {
        margin-top: 32px;

        button {
          padding: 10px 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .tournamentListWrapper {
    .tournamentListSubWrapper {
      padding: 32px 0 24px 0;
      max-width: 1000px;

      .tournamentListTabs {
        margin-top: 24px;

        button {
          padding: 10px 24px;
        }
      }

      .tournamentListTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .tournamentListWrapper {
    .tournamentListSubWrapper {
      padding: 24px 0 24px 0;
      max-width: 734px;

      .tournamentListTabs {
        margin-top: 24px;

        button {
          padding: 10px 16px;
          margin-right: 12px;
        }
      }

      .tournamentListTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }

      .tournamentList {
        margin-top: 16px;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .tournamentGrid {
        margin-top: 24px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .tournamentListWrapper {
    .tournamentListSubWrapper {
      padding: 16px 0 16px 0;
      max-width: 100%;

      .tournamentListTabs {
        margin-top: 16px;
        padding: 0 12px;
        gap: 8px;

        button {
          padding: 10px 16px;
        }
      }

      .tournamentListTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        padding: 0 12px;
      }

      .tournamentList {
        margin-top: 16px;
        padding: 0 8px;

        & > div {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .tournamentGrid {
        margin-top: 16px;
        padding: 0 8px;
      }
    }
  }
}
