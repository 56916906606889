.tournamentSinglePage {
  height: 100%;
  display: flex;
  justify-content: center;
  background: var(--background-color);
  padding-bottom: 50px;

  .tournamentSingleTopBg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 720px;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .tournamentSingleTopBgShadow {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), var(--background-color));
      height: 480px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .tournamentSingleWrapper {
    flex: 0 0 1400px;
    max-width: 1400px;
    margin-top: 720px;
    z-index: 999;

    .tournamentSingleLeft {
      position: absolute;
      cursor: pointer;
      top: 96px;
    }

    .tournamentSingleHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 99px;

      .tournamentSingleHeaderLeftSide {
        display: flex;
        flex-direction: column;

        .tournamentSingleTitle {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: var(--text-color);
          display: flex;
          align-items: center;

          .tournamentSingleStatus {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            background: #68C56C;
            border-radius: var(--item-tag-border-radius);
            padding: 2px 12px;
            margin-left: 10px;
            margin-right: 10px;
            color: black;
          }
        }

        .tournamentSinglePrize {
          margin-top: 12px;

          .tournamentSinglePrizeType {
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: var(--text-color);
          }

          .tournamentSinglePrizeAmount {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: var(--amount-color);
          }
        }

        .tournamentSinglePrizeStatus {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 16px;
          color: var(--text-color);
          gap: 8px;

          .tournamentSingleStatusMobile {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            background: #68C56C;
            border-radius: var(--item-tag-border-radius);
            padding: 2px 12px;
            margin-left: 10px;
            color: black;
          }
        }
      }

      .tournamentSingleHeaderRightSide {
        width: 178px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        overflow: hidden;

        .tournamentSingleJoin {
          margin-top: 16px;
        }

        :global {
          .ant-spin-blur {
            filter: blur(1px);
          }

          .tournamentItemStartDateWrapper {
            margin-right: 0;
          }
        }
      }
    }

    .periodWrapper {
      padding: 24px 0;
      //background-color: var(--background-color);

      .periodList {
        display: flex;
        overflow-x: auto;
        padding-bottom: 8px;
        margin-bottom: 5px;

        &::-webkit-scrollbar-thumb:horizontal {
          background: none;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        .periodItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          border-radius: var(--games-card-border-radius);
          background-color: var(--card-background-color);
          padding: 10px 16px;
          height: 56px;
          color: var(--text-color);
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          margin-right: 16px;
          cursor: pointer;
          box-sizing: border-box;

          .periodItemText {
            margin: 0;
            text-wrap: nowrap;
          }
        }

        .periodActive {
          color: var(--button-text-color);
          background-color: var(--primary-color);
        }

        .periodActiveLine {
          border-bottom: 4px solid #68C56C;
          left: 0;
          bottom: 0;
          border-bottom-left-radius: var(--button-border-radius);
          border-bottom-right-radius: var(--button-border-radius);
        }
      }
    }

    .tournamentSingleGamesWrapper {
      margin-top: 80px;

      .tournamentSingleGamesHeader {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;

        .tournamentSingleGamesTitle {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: var(--text-color);
          margin-left: 0.2px;
        }

        .tournamentSingleGamesSearchWrapper {
          .searchElement {
            border: 1px solid var(--border-color);
            background: var(--card-background-color);
            border-radius: var(--item-button-border-radius);
            padding: 8px 8px 8px 12px;
            box-shadow: none;

            :global {
              .ant-input-prefix {
                color: var(--placeholder-text-color);
              }
            }

            input {
              background: var(--card-background-color);
              color: var(--text-color);
              font-size: 14px;
              font-weight: 400;

              &::placeholder {
                color: var(--placeholder-text-color);
                font-size: 14px;
                font-weight: 400;
              }
            }

            :global {
              .ant-input-clear-icon {
                height: 16px;
              }
            }
          }

          .searchBox {
            :global {
              .ant-form-item {
                margin: 0;
              }
            }

            .searchElement {
              width: 268px;
            }
          }

          .searchBoxMobile {
            display: flex;


            .searchInputWrapper {
              position: absolute;
              width: 100%;
              right: 0;
              padding-right: 41px;
              box-sizing: border-box;

              .searchElement {
                width: 100%;
              }
            }

            .searchBoxBtn {
              position: relative;
              background-color: var(--card-background-color);
              border-radius: var(--item-button-border-radius);
              padding: 12px;
              width: 40px;
              height: 40px;
              cursor: pointer;
              box-sizing: border-box;
              color: var(--text-color)
            }
          }
        }
      }

      .tournamentSingleGames {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;

        .tournamentSingleGame {
          margin-bottom: 16px;
          background: var(--card-background-color);
          border: 1px solid var(--border-color);
          border-radius: var(--games-card-border-radius);
          padding: 8px 8px 12px;

          .tournamentSingleGameImage {
            position: relative;
            height: 144px;
            overflow: hidden;
            border-radius: var(--games-image-border-radius);

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              transition: transform .2s;
            }

            .tournamentSingleGameActions {
              position: absolute;
              display: flex;
              align-items: flex-end;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%);
              border-radius: var(--games-image-border-radius);
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              visibility: hidden;
              opacity: 0;
              transition: visibility 0.2s linear, opacity 0.2s linear;
              padding: 12px;
              box-sizing: border-box;

              .tournamentSingleGameActionsBtn {
                display: grid;
                gap: 0 8px;
                grid-template-columns: 1fr 1fr;
                width: 100%;

                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 6px 16px;
                  width: 100%;
                  cursor: pointer;
                  outline: none;
                  border: none;
                  border-radius: 26px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                }

                :global {
                  .playBtn {
                    background-color: #FEFEFE;
                    color: #28282C;
                    font-size: 12px;

                    span {
                      margin-left: 4px;
                    }
                  }

                  .demoBtn {
                    color: #FEFEFE;
                    font-size: 12px;
                    background-color: transparent;
                    border: 2px solid #FEFEFE;
                  }
                }
              }

              .tournamentSingleGameActionsBtnOnlyPlay {
                grid-template-columns: 1fr;
              }
            }

            &:hover {
              .tournamentSingleGameActions {
                visibility: visible;
                opacity: 1;
              }

              img {
                transform: scale(1.1);
              }
            }

          }

          .tournamentSingleGameImageMobile {
            visibility: visible;
            display: flex;
            opacity: 1;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              transition: transform .2s;
            }

            .tournamentSingleGameActions {
              .tournamentSingleGameActionsBtn {
                display: grid;
                gap: 8px 8px;
                grid-template-columns: 1fr;
                width: 100%;
              }
            }
          }

          .tournamentSingleGameTitle {
            margin-top: 8px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-left: 4px;
            color: var(--text-color);

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .tournamentSingleGameSubTitle {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: var(--placeholder-text-color);
            margin-top: 4px;
            margin-left: 4px;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .tournamentSingleGamesLoad {
        margin-top: 24px;
        text-align: center;

        button {
          display: inline-block;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          background: var(--primary-color);
          border-radius: var(--item-button-border-radius);
          padding: 10px 24px;
          border: none;
          color: var(--button-text-color);
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }

    .tournamentSingleTopLeaderboard {
      margin-top: 11px;
      display: flex;
      flex-wrap: wrap;

      .leaderboardRow {
        width: 100%;
      }

      .leaderboardItem {
        background: var(--card-background-color);
        border: 1px solid var(--border-color);
        border-radius: var(--games-card-border-radius);
        padding: 16px;
        box-sizing: border-box;

        .leaderboardItemPlaceInfo {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          border-bottom: 1px solid var(--divider-color);

          svg {
            width: 24px;
            height: 24px;
          }

          .leaderboardItemPlaceInfoTitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-color);
            margin-left: 12px;
          }
        }

        .leaderboardItemDetailInfo {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-top: 8px;
          gap: 2px;

          .leaderboardItemDetailInfoRow:last-child {
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }

          .leaderboardItemDetailInfoRow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .leaderboardItemDetailInfoColumnFirst {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: var(--text-color);
            }

            .leaderboardItemDetailInfoColumnSecond {
              margin-top: 2px;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: var(--amount-color);
            }
          }

          &:last-child .leaderboardItemDetailInfoColumnSecond {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .tournamentSingleRulesWrapper {
      margin-top: 80px;
      padding: 32px;
      background: var(--card-background-color);
      border: 1px solid var(--border-color);
      border-radius: var(--rules-card-border-radius);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--placeholder-text-color);

      .tournamentSingleRulesTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: var(--text-color);
        margin-bottom: 12px;
      }

      .tournamentSingleRules {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;

        & * {
          color: var(--text-color) !important;
        }

        ul, ol {
          padding-left: 17px;
          margin: 0;
        }
      }
    }

    .tournamentSingleLeaderboardPrizes {
      display: flex;
      margin-top: 80px;

      .tournamentSingleLeaderboardPrizesItem {
        border: 1px solid var(--border-color);
        border-radius: var(--leaderborad-card-border-radius);
        background-color: var(--card-background-color);
        height: 100%;
        overflow: hidden;

        .tournamentSingleLeaderboardPrizesItemTitle {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          padding: 16px 32px;
          border-bottom: 1px solid var(--divider-color);
          color: var(--text-color);
        }

        .tournamentSingleLeaderboardPrizesItemTable {
          max-height: 490px;
          overflow: auto;

          table {
            width: 100%;
            border-spacing: 0;

            thead {
              tr {
                background-color: var(--card-background-color);

                th {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  padding: 12px 16px 12px;
                  width: 30%;
                  color: var(--placeholder-text-color);
                }

                :global {
                  .th_prizes_1 {
                    width: 25%;
                    text-align: left;
                  }

                  .th_1 {
                    width: 10%;
                    text-align: left;
                  }

                  .th_2 {
                    text-align: left;
                  }

                  .th_3 {
                    text-align: left;
                  }

                  .th_4 {
                    text-align: right;
                  }

                  .th_5 {
                    width: 90%;
                    text-align: right;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  padding: 12px 16px 12px;
                  color: var(--text-color);
                }

                &:nth-child(odd) {
                  background-color: var(--card-background-color-opacity);
                }

                &:nth-child(even) {
                  background-color: var(--card-background-color);
                }

                :global {
                  .td_1 {
                    text-align: left;
                  }

                  .td_2 {
                    text-align: left;
                  }

                  .td_3 {
                    text-align: left;
                  }

                  .td_4 {
                    text-align: right;
                  }

                  .td_5 {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }

    .tournamentSingleMore {
      display: flex;
      flex-direction: column;
      margin-top: 80px;

      .tournamentSingleMoreWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tournamentSingleMoreTitle {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: var(--text-color);
        }

        .tournamentSingleMoreSeeALl {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--text-color);
          cursor: pointer;
        }
      }


    }
  }
}

.noResultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  .noResultTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--text-color);
  }

  .noResultSubTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--placeholder-text-color);
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .tournamentSinglePage {
    .tournamentSingleWrapper {
      .tournamentSingleHeader {
        .tournamentSingleHeaderLeftSide {
          align-items: start;

          .tournamentSingleStatusMobile {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1601px) {
  .tournamentSinglePage {
    .tournamentSingleTopBg {
      height: 480px;

      .tournamentSingleTopBgShadow {
        height: 280px;
      }
    }

    .tournamentSingleWrapper {
      flex: 0 0 1200px;
      max-width: 1200px;
      margin-top: 488px;

      .tournamentSingleHeader {
        margin-bottom: 79px;
      }

      .tournamentSingleLeft {
        top: 64px;
      }

      .tournamentSingleGamesWrapper {
        margin-top: 64px;
      }

      .tournamentSingleRulesWrapper {
        margin-top: 64px;
      }

      .tournamentSingleLeaderboardPrizes {
        margin-top: 64px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .tournamentSinglePage {
    .tournamentSingleTopBg {
      height: 480px;

      .tournamentSingleTopBgShadow {
        height: 280px;
      }
    }

    .tournamentSingleWrapper {
      flex: 0 0 1000px;
      max-width: 1000px;
      margin-top: 488px;

      .tournamentSingleLeft {
        top: 64px;
      }

      .tournamentSingleHeader {
        margin-bottom: 69px;
      }

      .tournamentSingleGamesWrapper {
        margin-top: 48px;

        .tournamentSingleGames {
          .tournamentSingleGame {
            margin-bottom: 12px;

            .tournamentSingleGameImage {
              height: 120px;
            }

            .tournamentSingleGameTitle {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }

            .tournamentSingleGameSubTitle {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      .tournamentSingleTopLeaderboard {
        .leaderboardItem {
          .leaderboardItemDetailInfo {
            overflow: hidden;

            .leaderboardItemDetailInfoRow:last-child {
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis !important;
            }

            .leaderboardItemDetailInfoRow {
              .leaderboardItemDetailInfoColumnSecond {
                font-size: 14px;
              }
            }
          }
        }
      }

      .tournamentSingleRulesWrapper {
        margin-top: 48px;
      }

      .tournamentSingleLeaderboardPrizes {
        margin-top: 48px;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .tournamentSinglePage {
    .tournamentSingleTopBg {
      height: 380px;

      .tournamentSingleTopBgShadow {
        height: 240px;
      }
    }

    .tournamentSingleWrapper {
      flex: 0 0 736px;
      max-width: 736px;
      margin-top: 388px;

      .tournamentSingleLeft {
        top: 51px;
      }

      .tournamentSingleHeader {
        margin-bottom: 59px;
      }

      .tournamentSingleGamesWrapper {
        margin-top: 40px;

        .tournamentSingleGames {
          .tournamentSingleGame {
            margin-bottom: 12px;

            .tournamentSingleGameImage {
              height: 120px;
            }

            .tournamentSingleGameTitle {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }

            .tournamentSingleGameSubTitle {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }

      .tournamentSingleTopLeaderboard {
        .leaderboardItem {
          .leaderboardItemDetailInfo {
            .leaderboardItemDetailInfoRow {
              .leaderboardItemDetailInfoColumnSecond {
                font-size: 14px;
              }
            }
          }
        }
      }

      .tournamentSingleRules {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .tournamentSingleRulesWrapper {
        margin-top: 40px;
      }

      .tournamentSingleLeaderboardPrizes {
        margin-top: 40px;

        .tournamentSingleLeaderboardPrizesItem {
          .tournamentSingleLeaderboardPrizesItemTitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding: 16px 16px;
          }

          .tournamentSingleLeaderboardPrizesItemTable {
            table {
              thead {
                tr {
                  th {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tournamentSinglePage {
    .tournamentSingleTopBg {
      height: 280px;

      .tournamentSingleTopBgShadow {
        height: 200px;
      }
    }

    .tournamentSingleWrapper {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      margin-top: 288px;

      .tournamentSingleLeft {
        top: 16px;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      .tournamentSingleHeader {
        flex-direction: column;
        align-items: start;
        text-align: center;
        margin-bottom: 32px;

        .tournamentSingleHeaderLeftSide {
          width: 100%;

          .tournamentSingleTitle {
            overflow-wrap: anywhere;
            justify-content: center;
          }

          .tournamentSinglePrize {
            .tournamentSinglePrizeAmount {
              font-size: 24px;
              line-height: 32px;
            }
          }
        }

        .tournamentSingleHeaderRightSide {
          margin-top: 16px;
          width: 100%;
          border-radius: 6px;
          overflow: hidden;

          .tournamentJoinBtn {
            width: 100%;
            height: 48px;
          }

          :global {
            .tournamentItemStartDate {
              justify-content: center;
            }
          }
        }
      }

      .tournamentSingleRules {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .tournamentSingleGamesWrapper {
        margin-top: 32px;

        .tournamentSingleGamesHeader {
          .tournamentSingleGamesTitle {
            font-size: 18px;
            line-height: 24px;
          }

          .tournamentSingleGamesSearchWrapper {
            .searchBoxMobile {
              .searchInputWrapper {
                padding-right: 48px;
              }
            }
          }
        }

        .tournamentSingleGames {
          margin-top: 16px;

          .tournamentSingleGame {
            margin-bottom: 8px;

            .tournamentSingleGameImage {
              height: 104px;
            }

            .tournamentSingleGameTitle {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }

            .tournamentSingleGameSubTitle {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        .tournamentSingleGamesLoad {
          margin-top: 10px;
          padding: 0 4px;

          button {
            width: 100%;
          }
        }
      }

      .tournamentSingleTopLeaderboard {
        overflow: auto;

        &::-webkit-scrollbar {
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        .leaderboardRow {
          flex-flow: nowrap;

          .leaderboardItem {
            padding: 12px 16px;

            .leaderboardItemPlaceInfo {
              padding-bottom: 12px;
            }

            .leaderboardItemDetailInfo {
              margin-top: 12px;
              overflow: hidden;

              .leaderboardItemDetailInfoRow:last-child {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
              }

              .leaderboardItemDetailInfoRow {
                .leaderboardItemDetailInfoColumnFirst {
                  font-size: 12px;
                }

                .leaderboardItemDetailInfoColumnSecond {
                  font-size: 14px;
                }
              }

            }
          }

          :global {
            .leaderboardItemColl {
              flex: 0 0 248px;
              max-width: 248px;
            }
          }
        }
      }

      .tournamentSingleRulesWrapper {
        margin-top: 32px;
        padding: 16px 12px;

        .tournamentSingleRules {
          margin-top: 8px;
          padding: 0;
        }
      }

      .tournamentSingleLeaderboardPrizes {
        flex-wrap: wrap;
        margin-top: 32px;

        .tournamentSingleLeaderboardPrizesItem {
          margin-bottom: 32px;

          .tournamentSingleLeaderboardPrizesItemTitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding: 16px 16px;
          }

          .tournamentSingleLeaderboardPrizesItemTable {
            table {
              thead {

                tr {
                  th {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modalAndSidebarWrapper {
  position: fixed;
  background-color: var(--background-color);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
