.sidebarWrapper {
  flex: 0 0 380px;
  height: 100%;
  padding: 16px 16px 12px 0;

  .sidebarContent {
    height: 100%;
    background-color: var(--card-background-color);
    border-radius: 15px;
    border: 1px solid var(--border-color);

    .sidebarHeader {
      display: flex;
      margin-bottom: 12px;
      align-items: center;
      border-bottom: 1px solid var(--divider-color);
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1601px) {
  .sidebarWrapper {
    flex: 0 0 360px;
  }
}

@media only screen  and (max-width: 1280px) {
  .sidebarWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    border-radius: unset;
    padding: 12px;
    background-color: var(--background-color);

    .sidebarContent {
      border-radius: 12px;
    }
  }

  .sidebarHeader {
    justify-content: space-around;
  }
}
