.typeStatus {
  display: flex;
  padding: 3px 8px;
  border: 1px solid var(--text-color);
  border-radius: var(--item-tag-border-radius);
  align-items: center;
  color: var(--text-color);

  .statusName {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color);
    margin-left: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}