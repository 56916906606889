.headerItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--placeholder-text-color);
  position: relative;
  flex: 1 1 auto;
  justify-content: center;

  .itemContent {
    position: relative;
    height: 100%;
    gap: 8px;
    display: flex;
    padding: 12px;
    align-items: center;

    .name {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    &:hover {
      color: var(--text-color);

      .icon {
        color: var(--text-color);
      }
    }

  }
}

.iconActive {
  color: var(--text-color);
}


.closeIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: var(--placeholder-text-color);

  &:hover {
    color: var(--text-color);
  }
}

.active {
  position: absolute;
  bottom: -2px;
  right: 10px;
  color: var(--text-color);
  width: 90%;
  height: 3px;
  border-top: 3px solid var(--text-color);
  border-radius: 16px 16px 16px 16px;
}

.nameActive {
  color: var(--text-color);
}

.headerItem::after {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  right: 0;
  background-color: var(--border-color);
}

.headerItem:last-child::after {
  content: unset;
}


@media screen and (max-width: 1280px) {
  .headerItem {
    position: relative;
    flex: 1 1 auto;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      width: 1px;
      height: 24px;
    }
  }

  .closeIcon {
    padding: 12px;
    width: unset;
    height: unset;
  }

  .active {
    width: 100%;
    left: 0;
  }
}