.tournamentItem {
  display: flex;
  position: relative;
  background: var(--card-background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--item-card-border-radius);
  padding: 8px 18px 8px 8px;
  cursor: pointer;

  .playerPosition {
    position: absolute;
    right: -1px;
    top: -1px;

    z-index: 5;
  }

  .tournamentItemImageSide {
    position: relative;
    flex-basis: 320px;
    max-height: 200px;
    flex-grow: 0;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: var(--item-image-border-radius);
      object-fit: cover;
    }
  }

  .tournamentItemContentSide {
    padding: 4px 0;
    margin-left: 16px;
    width: 100%;

    .tournamentItemIconsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 8px !important;
    }

    .tournamentItemTitle {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: var(--text-color);

      .tournamentItemTitleLeftSide {
        margin-right: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
      }
    }

    .tournamentItemContent {
      position: relative;
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
      height: 72px;
      overflow: hidden;

      .tournamentItemContentText {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
        word-break: break-all;
        -webkit-box-orient: vertical;
      }
    }

    .tournamentItemBottom {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .tournamentItemBottomRightSide {
        :global {
          .ant-spin-nested-loading {
            width: 100%;
          }

          .ant-spin-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 4px;
          }
        }

        display: flex;
        align-items: flex-end;
      }
    }
  }
}

.tournamentItemGrid {
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #474759;
  padding: 12px 12px 12px 12px;
  height: 100%;

  .tournamentItemImageSide {
    height: 240px;
    max-height: 240px;
    flex: none;

    img {
      width: 100%;
      height: 100%;
      max-height: 240px;
      border-radius: var(--item-image-border-radius);
    }
  }

  .tournamentItemContentSide {
    padding: 4px 4px;
    margin-left: 0;
    width: unset;

    .tournamentItemTitle {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 12px;

      .tournamentItemTitleLeftSide {
        .tournamentItemTitleSpan {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .typeStatus {
        margin-top: 8px;
        margin-left: 0;
      }
    }

    .tournamentItemContent {
      height: 64px;
    }

    .tournamentItemBottom {
      flex-direction: column;
      margin-top: 16px;

      .tournamentItemBottomRightSide {
        height: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 16px;
      }
    }
  }

  :global {
    .tournamentItemStartDateWrapper {
      margin-right: 0;
    }
  }

  .tournamentItemBottomLeftSide {
    .tournamentItemPrizeType {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }
  }
}

.tournamentItemBottomLeftSide {
  display: flex;
  align-items: flex-end;
  min-width: 200px;

  .tournamentItemPrizeType {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .tournamentItemPrizeTypeTitle {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: var(--text-color);
    }

    .tournamentItemPrizeTypeAmount {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: var(--amount-color);
    }
  }
}

.tabletPrizeType {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 12px;
  padding-top: 24px;
  padding-bottom: 8px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(255, 0, 0, 0), black);
  border-bottom-left-radius: var(--item-image-border-radius);
  border-bottom-right-radius: var(--item-image-border-radius);

  .tabletPrizeTypeTitle {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: white;
  }

  .tabletPrizeTypeAmount {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
  }
}

.typeStatus {
  display: flex;
  padding: 3px 8px;
  border: 1px solid var(--text-color);
  border-radius: var(--item-tag-border-radius);
  margin-left: 10px;

  .statusName {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color);
    margin-left: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .tournamentItem {
    .tournamentItemContentSide {
      .tournamentItemBottom {
        margin-top: 28px;
      }

      .tournamentItemContent {
        height: 64px;
      }
    }

    .tournamentItemBottomRightSide {
      justify-content: flex-end;
    }
  }

  .tournamentItemGrid {
    .tournamentItemContentSide {
      .tournamentItemBottom {
        margin-top: 16px;
      }
    }

    .tournamentItemJoin {
      width: 100%;
      margin-top: 32px;

      button {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tournamentItemGrid {
    .tournamentItemContentSide {
      .tournamentItemBottom {
        .tournamentItemBottomRightSide {
          height: max-content;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          :global {
            .tournamentItemStartDateWrapper {
              width: 100%;

              .tournamentItemStartDate {
                justify-content: center;
              }

              .tournamentItemStartDateTitle {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .tournamentItem {
    .tournamentItemBottomRightSide {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tournamentItem {
    .tournamentItemImageSide {
      flex-basis: 320px;
      max-height: 200px;
      flex-grow: 0;
      flex-shrink: 0;

      img {
        //max-height: 224px;
      }
    }

    .tournamentItemTitleLeftSide {
      margin-bottom: 10px;
    }

    .tournamentItemContentSide {
      .tournamentItemContent {
        height: 60px;
      }
    }
  }

  .tournamentItemGrid {
    padding: 8px 8px 8px 8px;

    .tournamentItemContentSide {
      .tournamentItemTitle {
        font-size: 18px;
        margin-top: 8px;
      }

      .tournamentItemBottom {
        .tournamentItemBottomRightSide {
          margin-top: 0;
          //height: 62px;
          justify-content: center;
        }
      }
    }

    .tournamentItemImageSide {
      flex-basis: 189px !important;
      flex-grow: 0;
      flex-shrink: 0;

      img {
        max-height: 189px !important;
      }
    }

    .tournamentItemJoin {
      width: 100%;
      margin-top: 8px;

      button {
        width: 100%;
      }
    }

    .tournamentItemStartDateWrapper {
      :global {
        .tournamentItemStartDateTitle {
          text-align: center;
        }
      }
    }
  }

  .tabletPrizeType {
    .tabletPrizeTypeTitle {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
    }

    .tabletPrizeTypeAmount {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .tournamentItem {
    .tournamentItemContentSide {
      .tournamentItemTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .tournamentItemJoin {
      width: 100%;
      margin-top: 10px;

      button {
        width: 100%;
      }
    }

    .tournamentItemBottomLeftSide {
      display: none;
    }

    .tournamentItemBottomRightSide {
      :global {
        .ant-spin-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 4px;

          .tournamentItemStartDateWrapper {
            margin: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tournamentItem {
    flex-direction: column;
    box-sizing: border-box;
    padding: 8px 8px 8px 8px;

    .tournamentItemImageSide {
      flex-basis: 189px !important;
      flex-grow: 0;
      flex-shrink: 0;

      img {
        height: 189px !important;
      }
    }


    .tournamentItemContentSide {
      padding: 4px 4px;
      margin-left: 0;
      width: unset;

      .tournamentItemTitle {
        align-items: flex-start;
        margin-top: 8px;
        font-size: 18px;

        .tournamentItemTitleLeftSide {
          margin-bottom: 8px;
          gap: 8px;
          flex-wrap: wrap;

        }

        .typeStatus {
          margin-top: 8px;
          margin-left: 0;
        }
      }

      .tournamentItemContent {
        height: 60px !important;
      }

      .tournamentItemBottom {
        margin-top: 12px;

        .tournamentItemBottomRightSide {
          height: unset;
          width: 100%;
          flex-direction: column;
          align-items: center !important;
          border-radius: 6px;
          overflow: hidden;

          :global {
            .ant-spin-nested-loading {
              width: 100%;
            }

            .ant-spin-container {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 8px;

              .tournamentItemStartDateWrapper {
                .tournamentItemStartDateTitle {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .tournamentItemJoin {
      width: 100%;
      margin-top: 12px;

      button {
        width: 100%;
      }
    }

  }

  .tabletPrizeType {
    .tabletPrizeTypeTitle {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
    }

    .tabletPrizeTypeAmount {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }

}

.tournamentItemContainer {
  min-width: 100%;
}

.btnContainer {
  width: 100%;
}