.joinBtn {
  height: 40px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  //background-color: var(--primary-color);
  border-radius: var(--item-button-border-radius);
  color: var(--button-text-color);
  padding: 0 24px;
  background: var(--primary-color);
  min-width: 160px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
}

.btn {
  min-width: 100% !important;
}

.joinBtn1 {
  background: transparent;
  border: 1px solid var(--text-color);
  opacity: 1;
  color: var(--text-color);

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.joinBtnSm {
  width: 100%;
}