:root {
  --primary-color: #4060E5;
  --background-color: #17171C;
  --card-background-color: #292939;
  --border-color: #474759;
  --divider-color: #474759;
  --text-color: #FFFFFF;
  --amount-color: #EAC33B;
  --button-text-color: #FFFFFF;
  --secondary-text-color: #ffffffb0;
  --placeholder-text-color: rbga(132.6, 132.6, 135.2, 1);
  --card-background-color-opacity: #292939;

  --button-border-radius: 8px;
  --item-card-border-radius: 16px;
  --item-image-border-radius: 12px;
  --item-tag-border-radius: 4px;
  --item-button-border-radius: 6px;
  --leaderborad-card-border-radius: 12px;
  --rules-card-border-radius: 16px;
  --games-card-border-radius: 12px;
  --games-image-border-radius: 8px;
}
