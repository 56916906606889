.popconfirmContainer {
  width: 320px;

  :global {
    .ant-popover-inner {
      padding: 16px !important;
      background-color: var(--card-background-color) !important;
      border: 1px solid var(--border-color);
      border-radius: 8px;

      .ant-popconfirm-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }

      .ant-btn {
        padding: 10px 24px;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;
        margin-inline-start: 0;
        color: var(--text-color);
        border: none;

        &.ant-btn-primary {
          background-color: var(--primary-color);
        }

        &.ant-btn-default {
          background: transparent;
          color: var(--text-color);
          border: 1px solid var(--text-color);

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        &:hover {
          background-color: var(--primary-color);
          opacity: 0.9;
        }
      }
    }

    .ant-popover-arrow::before {
      background-color: var(--card-background-color) !important;
    }
  }

  .popoverContent {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    max-width: 350px;
    gap: 4px;

    .popoverHeader {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow-wrap: break-word;

      h2 {
        max-width: 100%;
        word-break: break-all;
        color: var(--text-color);
        margin: 0;
      }
    }

    .popoverText {
      word-break: break-all;
      color: var(--text-color) !important;
      margin-bottom: 16px;

      span {
        word-break: break-all;
        color: var(--text-color) !important;
        margin: 0;
      }

      p {
        word-break: break-all;
        color: var(--text-color) !important;
        margin: 0;
      }
    }
  }
}


