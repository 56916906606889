.positionWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  border-top-right-radius: var(--item-card-border-radius);
  border-bottom-left-radius: var(--item-card-border-radius);
  background: var(--divider-color);

  .icon {
    color: var(--text-color);
    width: 16px;
    height: 16px;
  }

  .text {
    display: flex;
    gap: 4px;

    .playerText {
      color: var(--text-color);
    }

    .position {
      color: var(--amount-color);
    }
  }
}