.tournamentItemStatus {
  padding: 4px 8px;
  color: black;
  background-color: #68C56C;
  border-radius: var(--item-tag-border-radius);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.tournamentItemStatus2 {
  background-color: #EAC33B !important;
}

.tournamentItemStatus3 {
  background-color: #68C56C !important;
}

.tournamentItemStatus4, .tournamentItemStatus5 {
  background-color: #A9A9AA !important;
}

.tournamentItemStatus6 {
  display: flex;
  align-items: center;
  gap: 5.33px;
  background-color: #E35656 !important;
  color: black;

  span {
    width: 14px;
    height: 14px;
  }
}