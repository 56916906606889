.leaderboardWrapper {
  height: 90%;
  width: 100%;
  display: flex;

  .tournamentSingleLeaderboardPrizesItem {
    margin-bottom: 32px;
    height: 100%;
    width: 100%;
    overflow: auto;

    .tournamentSingleLeaderboardPrizesItemTable {
      width: 100%;

      table {
        border-collapse: collapse;
        width: 100%;
        height: auto;


        thead {
          background: var(--card-background-color);

          tr {
            th {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: var(--placeholder-text-color);
              text-align: left;

              &:nth-child(1) {
                padding: 0 8px 8px 16px;
              }

              &:nth-child(2) {
                padding: 0 8px 8px;
              }

              &:nth-child(3) {
                padding: 0 8px 8px;
              }

              &:last-child {
                text-align: right;
                padding: 0 16px 12px 8px;
              }
            }
          }
        }

        tbody {
          background: var(--background-color);

          tr {

            background: var(--card-background-color);

            td {
              color: var(--text-color);
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              text-align: left;

              &:nth-child(1) {
                padding: 12px 8px 12px 16px;
              }

              &:nth-child(2) {
                padding: 12px 8px;
              }

              &:nth-child(3) {
                padding: 12px 8px;
              }

              &:last-child {
                text-align: right;
                padding: 12px 16px 12px 8px;
              }
            }
          }

          tr:nth-child(odd) {
            background: var(--card-background-color-opacity);
          }
        }
      }
    }
  }
};