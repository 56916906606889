.notTournamentsContainer {
  margin-top: 40px;

  .notTournamentsContent {
    display: flex;

    .notTournamentsIcon {
      margin-bottom: 16px;

      svg {
        path {
          fill: var(--card-background-color);
        }

        path:nth-of-type(2) {
          fill: var(--placeholder-text-color);
          opacity: 0.1;
        }

        path:nth-of-type(3) {
          fill: var(--placeholder-text-color);
          opacity: 0.15;
        }

        path:nth-of-type(5) {
          fill: var(--placeholder-text-color);
          opacity: 0.1;
        }

        path:nth-of-type(7) {
          fill: var(--placeholder-text-color);
          opacity: 0.25;
        }

        path:nth-of-type(9) {
          fill: var(--placeholder-text-color);
          opacity: 0.25;
        }

        path:nth-of-type(11) {
          fill: var(--placeholder-text-color);
          opacity: 0.25;
        }

        path:nth-of-type(12) {
          fill: var(--placeholder-text-color);
          opacity: 0.25;
        }

        path:nth-of-type(14) {
          fill: var(--placeholder-text-color);
          opacity: 0.25;
        }

        path:nth-of-type(15) {
          fill: var(--placeholder-text-color);
          opacity: 0.25;
        }
      }
    }

    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .noTournamentsText {
    max-width: 380px;
    text-align: center;
    margin: 0;
    padding: 0 8px;
    color: var(--placeholder-text-color);
  }
}

