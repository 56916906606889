.gamesWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  height: 88%;
  overflow: auto;

  .games {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;

    .gameItem {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 4px 4px 8px;
      border-radius: 12px;
      cursor: pointer;
      background: var(--card-background-color);
      position: relative;
      border: 1px solid var(--border-color);

      &:hover .actionsWrapper {
        visibility: visible;
        opacity: 1;
      }

      .actionsWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        visibility: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 12px;
        box-sizing: border-box;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%);
        opacity: 0;
        transition: visibility 0.2s linear, opacity 0.2s linear;

        .gameActionsBtns {
          display: grid;
          width: 100%;
          gap: 8px;
          grid-template-columns: 1fr;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #FFFFFF;
            height: 28px;
            border-radius: 26px;
            color: #FFFFFF;
            flex: 1;
            font-size: 12px;

            &.playBtn {
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              background: #FFFFFF;
              border: none;
              cursor: pointer;
              color: black;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
              }
            }
          }
        }
      }

      .gameImg {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 108px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
          transition: transform .2s;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

      }

      .gameContent {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0 4px;

        .gameName,
        .providerName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .gameName {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: var(--text-color);
        }

        .providerName {
          font-size: 12px;
          line-height: 16px;
          color: var(--placeholder-text-color);
        }
      }
    }
  }

  .tournamentSingleGamesLoad {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    width: 100%;

    button {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background: var(--primary-color);
      border-radius: var(--item-button-border-radius);
      padding: 10px 24px;
      border: none;
      color: var(--button-text-color);
      cursor: pointer;
      min-width: 240px;
    }
  }

}


@media screen and  (min-width: 1281px) and (max-width: 1601px) {
  .gamesWrapper {
    height: 88%;

    .games {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      flex-wrap: wrap;
      gap: 8px;

      .gameItem {
        .gameImg {
          height: 88px;
        }
      }
    }
  }

}

@media only screen and (max-width: 1280px) {
  .gamesWrapper {
    height: 88%;

    .games {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 8px;
      padding: 4px;
    }

    .tournamentSingleGamesLoad {
      button {
        width: 25%;
      }
    }

    .gameItem {
      .actionsWrapper {
        .gameActionsBtns {
          grid-template-columns:1fr 1fr !important;
        }

        .gameActionsBtnsOnlyPlay {
          grid-template-columns:1fr !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 684px) {
  .gamesWrapper {
    height: 88%;

    .tournamentSingleGamesLoad {
      button {
        width: 100%;
      }
    }
  }
}


@media only screen and (max-width: 476px) {
  .gamesWrapper {
    height: 89%;

    .games {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 8px;

      .gameItem {
        .gameImg {
          height: 94px;
        }
      }
    }

    .gameItem {
      .actionsWrapper {
        .gameActionsBtns {
          grid-template-columns: 1fr !important;
        }
      }
    }
  }

}


@media only screen and (max-width: 376px) {
  .gamesWrapper {
    height: 85%;
    padding: 0 8px;

    .games {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      gap: 8px;
    }
  }
}

