.tooltipContainer {
  width: 200px;
  :global {
    .ant-tooltip-content {
      border: 2px solid var(--border-color) !important;
      border-radius: 8px;
      .ant-tooltip-inner {
        background-color: var(--card-background-color);
        color: var(--text-color) !important;
      }
    }
    .ant-tooltip-arrow::before {
      background-color: var(--card-background-color);
    }
  }
}